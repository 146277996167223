<template>

    <div>
       <a-modal :visible="newBrickModal.show" centered @ok="addBrick" @cancel="closenewBrick" title="Add new Exterior Color Package" :confirmLoading="newBrick.processing" okText="Create Package">
            <a-form>
                <a-form-item label="Name" class="mb-0">
                    <a-input v-model="newBrick.name" />
                </a-form-item>
                <a-form-item label="Description" class="mb-0">
                    <a-input v-model="newBrick.description" />
                </a-form-item>
                <a-row>
                    <a-col :span="12">
                        <a-form-item label="Thumbnail" class="mb-0">
                            <ImageBoxSelector :key="newBrick.thumbnail" v-model="newBrick.thumbnail" type="images" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="Image" class="mb-0">
                            <ImageBoxSelector :key="newBrick.image" v-model="newBrick.image" type="images" />
                        </a-form-item>
                    </a-col>
                </a-row>


            </a-form>
        </a-modal>

        <!-- <div style="display:flex; justify-content:flex-end" class="mb-4">
            <a-button icon="plus" type="primary" @click="newBrickModal.show = true">Add new Exterior Color Package</a-button>
        </div> -->

        <a-table v-if="false" :columns="columns" :data-source="bricks" bordered :loading="isLoading" @change="handleTableChange">
            <template
                slot="name"
                slot-scope="text, record"
                >
                <div>
                    <a-input
                        v-if="record.editable"
                        style="margin: -5px 0"
                        :value="text"
                        @change="e => handleChange(e.target.value, record.id, 'name')"
                    />
                    <template v-else>
                        {{ text }}
                    </template>
                </div>
            </template>
            <template
                slot="description"
                slot-scope="text, record"
                >
                <div>
                    <a-textarea
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    @change="e => handleChange(e.target.value, record.id, 'description')"
                    />
                    <template v-else>
                    {{ text }}
                    </template>
                </div>
            </template>


            <template slot="order" slot-scope="text, record">
                    <a-input-number v-model="record.order" v-if="record.editable"  />
                    <span v-else>{{record.order || 0}}</span>
            </template>

            <template
                slot="thumbnail"
                slot-scope="text, record"
                >
                <ImageBoxSelector :key="newTime" :value="text" type="images" v-if="record.editable" style="width:50px;"  @input="e => handleChange(e, record.id, 'thumbnail')"/>
                <a-avatar :src="text" shape="square" v-else/>

            </template>

            <template
            slot="image"
            slot-scope="image, record"
            >
                <ImageBoxSelector :key="newTime" :value="image" type="images" v-if="record.editable" style="width:50px;"  @input="e => handleChange(e, record.id, 'image')"/>
                <a-avatar :src="image" shape="square" v-else />

            </template>

            <template slot="operation" slot-scope="text, record">
                <div class="editable-row-operations">
                    <span v-if="record.editable" style="display:flex;">
                        <a-button @click="save(record.id)" icon="check" type="primary" />
                        <a-popconfirm title="Sure to cancel?" @confirm="() => cancel(record.id)">
                            <a-button icon="close" class="ml-2"/>
                        </a-popconfirm>
                    </span>
                    <span v-else>
                        <a-button :disabled="editingKey !== ''" @click="() => edit(record.id)" type="secondary" icon="edit" size="small" />
                        <!-- <a :disabled="editingKey !== ''" @click="() => edit(record.id)">Edit</a> -->
                    </span>
                </div>
            </template>
        </a-table>

        <template v-if="origBricks && origBricks.length">
            <div class="w-full dF jE">
                <div class="dF aC">
                    <i @click="changeDisplay('card')" :style="viewType == 'card'? 'color:var(--orange)': 'color:#D3D0D6'"  style="cursor:pointer; font-size:25px" class="mr-3 fa fa-th" />
                    <i @click="changeDisplay('list')" :style="viewType == 'list'? 'color:var(--orange)': 'color:#D3D0D6'" style="cursor: pointer; font-size:25px" class="fa fa-th-list" />
                </div>
            </div>
			<div class="w-full dF jE mt-2">
				<a-select @change="selectedBulk" class="change-select-color" v-if="selectedBricks.length > 1 && $p > 10" placeholder="Bulk Actions" style="width:200px;">
					<a-select-option value="delete">Delete Selected Packages</a-select-option>
				</a-select>
			</div>
            <template v-if="viewType == 'card'">
                <div class="grid-features mt-4">
                    <div v-for="(brick,brickI) in origBricks" :key="brick+brickI">
                        <BricksCard :brick="brick" :selected="selectedBricks" @selectBrick="selectBrick" />
                    </div>
                </div>
            </template>
            <div v-else class="w-full mt-4">
                <a-table :rowKey="(e) => e.id" class="white-table" :columns="newColumns" :data-source="origBricks" :pagination="false" :row-selection="{ selectedRowKeys: selectedBricks, onChange: onSelectChange }">
                    <div slot="thumbnail" slot-scope="obj">
                        <img :src="obj.thumbnail" style="object-fit: contain; width: 150px; max-height: 100px;" />
                    </div>
                    <div slot="description" slot-scope="obj" style="color:#9EA0A5">
                        <div v-html="obj.description"></div>
                    </div>
                    <div slot="visibility" slot-scope="obj" style="font-weight: bold;">
                        {{obj.status}}
                    </div>
                    <div slot="action" slot-scope="obj" class="dF">
                        <a-popover overlayClassName="no-padding" trigger="focus">
                            <div slot="content">
								<div @click="previewBrick(obj)" style="cursor:pointer" class="option dF aC p-2">
									<div class="mr-3">
										<a-icon type="eye" />
									</div>
									<div>Preview</div>
								</div>
                                <div @click="editBrick(obj)" style="cursor:pointer" class="option dF aC p-2">
                                    <div class="mr-3">
                                        <a-icon type="edit" />
                                    </div>
                                    <div>Edit</div>
                                </div>
                                <div @click="duplicateBrick(obj)" style="cursor:pointer" class="option dF aC p-2">
                                    <div class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg></div>
                                    <div>Duplicate</div>
                                </div>
                                <div v-if="$p > 10" @click="deleteBrick(obj)" style="cursor:pointer" class="option dF aC p-2">
                                    <div class="mr-3" style="color:#FD647C"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></div>
                                    <div>Delete</div>
                                </div>
                            </div>
                            <div class="relative more-option dF aC" tabindex="0">
                                <div class="absolute circle" style="background-color:#fff; width:42px; height:42px; border-radius:50%; left:-15px"></div>
                                <a-icon class="more-icon" style="z-index:10" type="more" />
                            </div>
                        </a-popover>
                    </div>
                </a-table>
            </div>
        </template>
    </div>

</template>


<script>




import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import BricksCard from '@/components/features/BricksCard'

export default {
    components:{
        ImageBoxSelector,
        BricksCard,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        },
    },
    data() {
        return {
            newColumns:[
                {
                    title:'Thumbnail',
                    key:'thumbnail',
                    slots:{title:'thumbnail'},
                    scopedSlots:{customRender:'thumbnail'}
                },
                {
                    dataIndex:"name",
                    key:"name",
                    title:'Title'
                },
                {
                    title:'Description',
                    key:'description',
                    slots:{title:'description'},
                    scopedSlots:{customRender:'description'}
                },
                {
                    title:'Visibility',
                    key:'visibility',
                    slots:{title:'visibility'},
                    scopedSlots:{customRender:'visibility'}
                },
                {
                    title:'',
                    key:'action',
                    slots:{title:'Actions'},
                    scopedSlots:{customRender:'action'}
                }
            ],
            viewType:'card',


            newGroupModalProcessing:false,
            manageGroupNew:false,
            newGroupName:'',
            newBrickModal:{
                show:false,
                processing:false,
                showError:false,
            },
            newBrick:{
                name:'',
                description:'',
                thumbnail:'',
                image:'',
            },
            editGroup:{
                show:false,
                id:'',
                name:''
            },
            manageGroupModal:{
                show:false,
            },
            newGroupModal:{
                show:false,
                processing:false,
                id:'',
                name:'',
            },
            isLoading:false,
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    width: '25%',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: 'Description',
                    dataIndex: 'description',
                    width: '40%',
                    scopedSlots: { customRender: 'description' },
                },
                {
                    title: 'Thumbnail',
                    dataIndex: 'thumbnail',
                    scopedSlots: { customRender: 'thumbnail' },
                },
                {
                    title: 'Image',
                    dataIndex: 'image',
                    scopedSlots: { customRender: 'image' },
                },
                {
                    title: 'Order',
                    dataIndex: 'order',
                    scopedSlots: { customRender: 'order' },
                },
                {
                    title: '',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
            editingKey: '',
            bricks:[],
            cacheBricks:[],
            filters:{},

			selectedBricks:[],
        };
    },
    watch:{
        newBrickModal:{
            handler(val, oldVal){
                if (val.show && val.show !== oldVal.show){
                    this.editingKey = ''
                    this.newBrick = {
                        name:'',
                        description:'',
                        thumbnail:'',
                        group:this.groups.length?this.groups[0].id : '',
                        data:{
                            mediaType:'image',
                            media:''
                        }
                    }

                }
            },
            deep:true
        },
        filters(val){
            this.editingKey = ''
        },
        manageGroupNew(){
            this.newGroupName = ''
        },
    },
    methods: {
		selectedBulk(e){
			console.log('E', e);
			if (e == 'delete'){
				let self = this;
				this.$confirm({
					title: 'Bulk Delete',
					content: 'Are you sure you want to delete these Exterior Color Packages?',
					okText: 'Yes',
					okType: 'danger',
					centered: true,
					cancelText: 'No',
					onOk() {
						self.deleteBricks();
					},
					onCancel() {
						console.log('Cancel');
					},
				});
			}
		},
		deleteBricks(){
			let sendObj = {ids:this.selectedBricks}
			this.isLoading = true;
			this.$api.post(`/bricks/:instance/bulk-delete`, sendObj).then(({data}) =>{
				console.log('DATA COMING BACK', data);
				if (data.success){
					this.$store.commit('REMOVE_BRICKS', this.selectedBricks);
					this.isLoading = false;
					this.selectedBricks = [];
				}
			}).catch((err) =>{
				console.log('err', this.$err(err));
				let self = this;
				this.$confirm({
					title: 'Bulk Delete',
					content:'Do you still want to force delete these Exterior Color Packages?',
					okText: 'Yes',
					okType: 'danger',
					centered: true,
					cancelText: 'No',
					onOk() {
						self.deleteBricksForce();
					},
					onCancel() {
						console.log('Cancel');
					},
				});
			})
		},
		deleteBricksForce(){
			let sendObj = {ids:this.selectedBricks}
			this.$api.post(`/bricks/:instance/bulk-delete?force=1`, sendObj).then(({data}) =>{
				console.log('DATA COMING BACK', data);
				if (data.success){
					this.$store.commit('REMOVE_BRICKS', this.selectedBricks);
				}
				this.isLoading = false;
				this.selectedBricks = [];
			}).catch((err) =>{
				console.log('err', this.$err(err));
				this.isLoading = false;
			})
		},
		onSelectChange(selectedRowKeys) {
			console.log('selectedRowKeys changed: ', selectedRowKeys);
			this.selectedBricks = selectedRowKeys;
		},
		selectBrick(id){
			if (this.selectedBricks.includes(id)){
				this.selectedBricks = this.selectedBricks.filter(item => item !== id)
			} else {
				this.selectedBricks.push(id)
			}
		},
		previewBrick(obj){
			console.log('PREVIEWING BRICK...', obj)
			this.$store.commit('SHOW_PREVIEW', obj)
		},
        changeDisplay(type){
            console.log('CHANGING DISPLAY...')
            this.viewType = type
        },
        editBrick(obj){
            console.log('EDITING BRICK...', obj)
            this.$router.push('/editbrick/' + obj.id)
        },
        duplicateBrick(obj){
            console.log('DUPLICATING BRICK...', obj)
            let newObj = JSON.parse(JSON.stringify(obj))
            newObj.name = obj.name + ' (copy)'
            delete newObj.id

            this.$store.commit('LOAD_PAGE',true)
            this.$api.post(`/bricks/${this.$store.state.instance.id}`, newObj).then( ( {data} ) => {

                this.$store.commit('LOAD_PAGE',false)
                this.$store.commit('SET_PROP',{where:['bricks', data.id], what:data})

                console.log('ORIGINAL BRICKS', this.origBricks)

                this.$message.success('Successfully duplicated Exterior Color Package')

            }).catch(err => {

                this.$store.commit('LOAD_PAGE',false)
                console.error('ERRR', this.$err(err))
                this.$message.error(this.$err(err))

            })
        },
        deleteBrick(obj){
            console.log('DELETING BRICK...', obj)
            let self = this
            if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')
            this.$confirm({
                title: 'Delete Exterior Color Package',
                content: h => <div>Do you want to delete this Exterior Color Package?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered:true,
                onOk() {
                    console.log('CONFIRM DELETE', obj)
                    self.$api.delete(`/bricks/:instance/${obj.id}`).then( ({data}) => {
                        console.log('DATA FROM DELETE API ', data)
                        self.$store.commit('SET_PROP',{where:['bricks', data.id], what:data, del:true})
                    }).catch(err => {
                        console.error('ERRR', self.$err(err))
                        self.$message.error(self.$err(err))
                    })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
        addBrick(){
            if (!this.newBrick.name.trim() || !this.newBrick.thumbnail || !this.newBrick.image) return this.$message.error('Please review and try again.'),this.newBrickModal.showError = true

            this.newBrickModal.processing = true

            let lastNum = 0
            this.origBricks.forEach(b => {
                if (b.order > lastNum) lastNum = b.order
            })


            this.newBrick.order = lastNum + 1


            this.$api.post(`/bricks/${this.$store.state.instance.id}`, this.newBrick).then( ( {data} ) => {

                data.group = typeof data.group === 'object'? data.group.id : data.group

                this.newBrickModal.processing = false
                this.$store.commit('SET_PROP',{where:['bricks', data.id], what:data})
                this.bricks = JSON.parse(JSON.stringify(this.origBricks))
                this.cacheBricks = JSON.parse(JSON.stringify(this.origBricks))


                this.$message.success('Successfully added Package')
                this.closenewBrick()

            }).catch(err => {

                this.newBrickModal.processing = false
                this.$message.error(this.$err(err))

            })


        },
        closenewBrick(){
            this.newBrickModal.show = false
        },
        closeModals(){

            this.manageGroupModal = {
                show:false
            }
        },
        addNew(){
            let id = '5c' + Date.now()
            this.bricks = [
                {
                    id,
                    name:'Name Goes Here',
                    group:'group1',
                    new:true,
                    description:'Description goes here.....',
                    thumbnail:'',
                    editable:true,
                    data:{
                        mediaType:'image',
                        media:'',
                    }
                },
                ...this.bricks
            ]
            this.editingKey = id
        },
        handleTableChange(pagination, filters, sorter) {
            this.filters = filters
            console.log(pagination, filters, sorter);
            // const pager = { ...this.pagination };
            // pager.current = pagination.current;
            // this.pagination = pager;

            // this.fetch({
            //     results: pagination.pageSize,
            //     page: pagination.current,
            //     sortField: sorter.field,
            //     sortOrder: sorter.order,
            //     ...filters,
            // });
        },
        handleChange(value, key, column) {
            column = column.split('.')
            const newData = [...this.bricks];
            const target = newData.find(item => key === item.id);
            if (target) {
                if (column.length === 1) target[column[0]] = value;
                if (column.length === 2) target[column[0]][column[1]] = value;
                if (column.length === 3) target[column[0]][column[1]][column[2]] = value;
                this.bricks = newData;
            }

        },
        edit(key) {

            const newData = [...this.bricks];
            const target = newData.find(item => key === item.id)
            this.editingKey = key;
            if (target) {
                target.editable = true;
                this.bricks = newData;
            }

        },
        async save(key) {

            const newData = [...this.bricks];
            const newCacheData = [...this.cacheBricks];
            const target = newData.find(item => key === item.id)
            const targetCache = newCacheData.find(item => key === item.id)


            if (target && targetCache ) {

                if (!target.name.trim() || !target.thumbnail.trim()) return this.$message.error('Please check your fields and try again.')

                this.isLoading = true

                this.$api.put(`/bricks/${this.$store.state.instance.id}/${target.id}`, target).then( ( {data} ) => {

                    this.$store.commit('SET_PROP',{where:['bricks', data.id], what:data})
                    delete target.editable;
                    this.isLoading = false
                    this.bricks = newData;
                    Object.assign(targetCache, target);
                    this.cacheBricks = newCacheData;

                }).catch(err => {

                    this.isLoading = false
                    this.$message.error(this.$err(err))

                })




            }

            this.editingKey = '';

        },
        cancel(key) {
            const newData = [...this.bricks];
            const target = newData.find(item => key === item.id);
            this.editingKey = '';
            if (target) {
                Object.assign(target, this.cacheBricks.find(item => key === item.id));
                delete target.editable;
                this.bricks = newData;
            }

        },
    },
    computed:{
        newTime(){
            return this.$store.state.features.newTime
        },
        origBricks(){
            return Object.values(this.$store.state.features.bricks)
        },
    },
    created(){
        this.bricks = JSON.parse(JSON.stringify(this.origBricks))
        this.cacheBricks = JSON.parse(JSON.stringify(this.origBricks))
    }
};
</script>

<style scoped lang="scss">
    @media screen and (min-width: 1280px) {
        .grid-features{
            display:grid;
            grid-gap:25px;
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
    @media screen and (max-width: 1280px) {
        .grid-features{
            display:grid;
            grid-gap:25px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
    @media screen and (max-width: 768px) {
        .grid-features{
            display:grid;
            grid-gap:25px;
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
    .image-holder-box{
        width: 100%;
        max-width:100% !important;
    }
    .editable-row-operations a {
        margin-right: 8px;
    }
    .option:hover {
        background-color:#FAF9F9;
        cursor:pointer;
    }
    .more-option:hover .circle {
        opacity: 1;
        cursor:pointer;
    }
    .more-option:hover .more-icon {
        color:var(--orange);
        cursor:pointer;
    }
    .more-option:not(:hover) .circle {
        opacity: 0;
    }
</style>

<style lang="scss">
	.change-select-color .ant-select-selection__placeholder{
		color:#000 !important;
	}
    p {
        margin-bottom: 0;
    }
    .no-padding .ant-popover-inner-content{
        padding:0 !important;
        min-width:150px;
    }
    .preview-features-desc{
        font-size:10px;
        h3{
            font-size:12px !important;
        }
        > * {
            margin:0;
            & + *{
                margin-top:5px;
            }
        }
        max-height:40px;
        overflow: hidden;


    }
</style>
