<template>
    <a-card :title="null" class="cursor-pointer purple-hover relative features-card" hoverable :bodyStyle="{padding:0}">
		<a-checkbox :checked="selected.includes(brick.id)" @change="selectBrick" class="absolute" style="right: 15px; top: 10px; z-index: 1;" />
		<div>
            <div class="px-3 pt-3" @click="editBrick()">
                <div class="image-holder" :style="{backgroundImage:`url(${brick.thumbnail})`}" />
            </div>
            <div class="dF aE p-3" style="text-align: left;">
                <div class="f1" @click="editBrick()">
                    <div style="font-size: 20px;">{{brick.name}}</div>
					<div style="color: #9EA0A5; font-size: 16px;">{{brick.status ? brick.status.charAt(0).toUpperCase() + brick.status.slice(1) : 'Draft'}}</div>
                </div>
                <div>
                    <a-popover overlayClassName="no-padding" trigger="focus">
                        <div slot="content">
                            <div @click="previewBrick()" style="cursor:pointer" class="option dF aC p-2">
                                <div class="mr-3">
                                    <a-icon type="eye" />
                                </div>
                                <div>Preview</div>
                            </div>
							<div @click="editBrick()" style="cursor:pointer" class="option dF aC p-2">
                                <div class="mr-3">
                                    <a-icon type="edit" />
                                </div>
                                <div>Edit</div>
                            </div>
                            <div @click="duplicateBrick()" style="cursor:pointer" class="option dF aC p-2">
                                <div class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg></div>
                                <div>Duplicate</div>
                            </div>
                            <div v-if="$p > 10" @click="deleteBrick()" style="cursor:pointer" class="option dF aC p-2">
                                <div class="mr-3" style="color:#FD647C"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></div>
                                <div>Delete</div>
                            </div>
                        </div>
                        <div class="relative more-option dF aC" tabindex="0">
                            <div class="absolute circle" style="background-color:#fff; width:42px; height:42px; border-radius:50%; left:-15px"></div>
                            <a-icon class="more-icon" style="z-index:10" type="more" />
                        </div>
                    </a-popover>
                </div>
            </div>
        </div>
    </a-card>
</template>

<script>

export default {
	components:{

	},
    props:['brick', 'selected'],
    computed:{

    },
	data(){
		return{

		}
	},
    methods:{
		selectBrick(){
			this.$emit('selectBrick', this.brick.id)
		},
		previewBrick(){
			console.log('PREVIEWING BRICK...', this.brick)
			this.$store.commit('SHOW_PREVIEW', this.brick)
		},
        editBrick(){
            console.log('EDITING BRICK...', this.brick)
            this.$router.push('/editbrick/' + this.brick.id)
        },
        duplicateBrick(){
            console.log('DUPLICATING BRICK...', this.brick)
            let newObj = JSON.parse(JSON.stringify(this.brick))
            newObj.name = this.brick.name + ' (copy)'
            delete newObj.id

            this.$store.commit('LOAD_PAGE',true)
            this.$api.post(`/bricks/${this.$store.state.instance.id}`, newObj).then( ( {data} ) => {

                this.$store.commit('LOAD_PAGE',false)
                this.$store.commit('SET_PROP',{where:['bricks', data.id], what:data})

                console.log('ORIGINAL BRICKS', this.origBricks)

                this.$message.success('Successfully duplicated Package')

            }).catch(err => {

                this.$store.commit('LOAD_PAGE',false)
                this.$message.error(this.$err(err))

            })
        },
        deleteBrick(){
            if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')
            console.log('DELETING BRICK...', this.brick)
            let self = this
            this.$confirm({
                title: 'Delete Exterior Color Package',
                content: h => <div>Do you want to delete this Exterior Color Package?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered:true,
                onOk() {
                    self.confirmDeleteBrick();
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
		confirmDeleteBrick(){
			console.log('CONFIRM DELETE', this.brick)
			this.$api.delete(`/bricks/:instance/${this.brick.id}`).then( ({data}) => {
				console.log('DATA FROM DELETE API ', data)
				this.$store.commit('SET_PROP',{where:['bricks', data.id], what:data, del:true})
			}).catch((err) => {
				let self = this;
				this.$confirm({
					title: 'Delete Exterior Color Package',
					content:'Do you still want to force delete this Exterior Color Package?',
					okText: 'Yes',
					okType: 'danger',
					centered: true,
					cancelText: 'No',
					onOk() {
						self.deleteBrickForce();
					},
					onCancel() {
						console.log('Cancel');
					},
				});
			})
		},
		deleteBrickForce(){
            this.$api.delete(`/bricks/:instance/${this.brick.id}?force=1`).then( ({data}) => {
				console.log('DATA FROM DELETE API ', data)
				this.$store.commit('SET_PROP',{where:['bricks', data.id], what:data, del:true})
			})
        },
    }
}
</script>

<style lang="scss">
    .no-padding .ant-popover-inner-content{
        padding:0 !important;
        min-width:150px;
    }
    .features-card{
        .image-holder{
            height:0;
            width:100%;
            padding-bottom:50%;
            position:relative;
            background-size:cover;
            background-repeat:no-repeat;
            background-position: center;
            background-color:var(--off-white-dark);
        }
    }
</style>

<style scoped>
    .option:hover {
        background-color:#FAF9F9;
        cursor:pointer;
    }
    .more-option:hover .circle {
        opacity: 1;
        cursor:pointer;
    }
    .more-option:hover .more-icon {
        color:var(--orange);
        cursor:pointer;
    }
    .more-option:not(:hover) .circle {
        opacity: 0;
    }
</style>
