<template>

    <div>
        <a-modal :visible="manageGroupNew" centered @ok="makeNewGroup" @cancel="closeNewGroup" title="Add new Group" :confirmLoading="newGroupModalProcessing" okText="Create Group">
            <a-form>
                <a-form-item label="Enter group name">
                    <a-input v-model="newGroupName" />
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal :visible="newFeatureModal.show" centered @ok="addFeature" @cancel="closeNewFeature" title="Add new Feature" :confirmLoading="newFeature.processing" okText="Create Feature">
            <a-form>
                <a-form-item label="Name" class="mb-0">
                    <a-input v-model="newFeature.name" />
                </a-form-item>
                <a-form-item label="Description" class="mb-0">
                    <a-input v-model="newFeature.description" />
                </a-form-item>
                <a-form-item label="Group" class="mb-0">
                    <a-select v-model="newFeature.group"  style="width:100%;" >
                        <a-select-option v-for="group in groups" :value="group.id" :key="group.id">{{group.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-row>
                    <a-col :span="12">
                        <a-form-item label="Thumbnail" class="mb-0">
                            <ImageBoxSelector :key="newFeature.thumbnail" v-model="newFeature.thumbnail" type="images" style="max-width:100%;" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item label="Image" class="mb-0">
                            <ImageBoxSelector :key="newFeature.data.media" v-model="newFeature.data.media" type="images" />
                        </a-form-item>
                    </a-col>
                </a-row>


            </a-form>
        </a-modal>
        <a-modal :visible="manageGroupModal.show" @ok="closeModals" @cancel="closeModals" title="Manage Groups" >
            <div class="dF fC">
                <div class="pr-2 py-2 pl-3 bg-offwhite-dark mb-1 dF jSB aC" v-for="(grp,grpI) in groups" :key="grp.id + 'da'">
                    <div>
                        <a-input v-if="editGroup.show && editGroup.id === grp.id" v-model="editGroup.name" />
                        <span v-else>{{grp.name}}</span>
                    </div>

                    <div>
                        <div v-if="editGroup.show && editGroup.id === grp.id">
                            <a-button icon="check" @click="doEditGroup(grp)" />
                            <a-button class="ml-2" icon="close" @click="resetEditGroup"/>
                        </div>
                        <div v-else>
                            <a-button icon="edit" @click="showEditGroup(grp)"/>
                            <a-popconfirm title="Are you sure you want to delete?" @confirm="() => deleteGroup(grp.id)" v-if="grpI !== 0">
                                    <a-button class="ml-2" icon="delete"/>
                            </a-popconfirm>
                        </div>
                    </div>

                </div>
                <div class="py-3">
                    <!-- <a-popover v-model="manageGroupNew" title="Create new Feature Group" trigger="click" placement="bottomRight">
                        <div slot="content">

                            <a-input-search v-model="newGroupName" placeholder="Group name" @search="makeNewGroup">
                                <a-button type="primary" icon="check" slot="enterButton"/>
                            </a-input-search>

                            <a-form v-if="false">
                                <a-form-item label="Group name">
                                </a-form-item>
                            </a-form>
                        </div>
                        <a-button icon="plus" >New Group</a-button>
                    </a-popover> -->
                    <a-button icon="plus" @click="manageGroupNew = true">New Group</a-button>

                </div>
            </div>
        </a-modal>
        <!-- <div style="display:flex; justify-content:flex-end" class="mb-4">
            <a-button icon="unordered-list" type="secondary" @click="manageGroupModal.show = true" class="mr-4">Manage Feature Groups</a-button>
            <a-button icon="plus" type="primary" @click="newFeatureModal.show = true">Add new feature</a-button>
        </div> -->
        <a-table v-if="false" :columns="columns" :data-source="features" bordered :loading="isLoading" @change="handleTableChange">
            <template
                v-for="col in ['name']"
                :slot="col"
                slot-scope="text, record"
                >
                <div :key="col">
                    <a-input
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    @change="e => handleChange(e.target.value, record.id, col)"
                    />
                    <template v-else>
                    {{ text }}
                    </template>
                </div>
            </template>
            <template slot="description" slot-scope="text, record">
                <div key="add2ress">

                    <!-- <a-textarea

                    style="margin: -5px 0"
                    :value="text"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    @change="e => handleChange(e.target.value, record.id, 'description')"
                    /> -->

                    <Tiptap v-model="record.description" v-if="record.editable" />

                    <div v-else v-html="text" class="preview-features-desc">
                    </div>
                </div>
            </template>
            <template slot="order" slot-scope="text, record">
                    <a-input-number v-model="record.order" v-if="record.editable"  />
                    <span v-else>{{record.order || 0}}</span>
            </template>


            <template
            slot="thumbnail"
            slot-scope="text, record"
            >
                <ImageBoxSelector :key="newTime" :value="text" type="images" v-if="record.editable" style="width:50px;"  @input="e => handleChange(e, record.id, 'thumbnail')"/>
                <a-avatar :src="text" shape="square" v-else/>

            </template>

            <template
            slot="data"
            slot-scope="data, record"
            >
                <ImageBoxSelector :key="newTime" :value="data.media" type="images" v-if="record.editable" style="width:50px;"  @input="e => handleChange(e, record.id, 'data.media')"/>
                <a-avatar :src="data.media" shape="square" v-else />

            </template>

            <template
            slot="group1"
            slot-scope="text, record"
            >
                <a-select :value="text" v-if="record.editable" style="width:100%;" @change="e => handleChange(e, record.id, 'group')">
                    <!-- <div slot="dropdownRender" slot-scope="menu" v-if="false">
                        <v-nodes :vnodes="menu" />
                        <a-divider style="margin: 4px 0;" />
                        <div
                            style="padding: 4px 8px; cursor: pointer;"
                            @mousedown="e => e.preventDefault()"
                            @click="showAddGroup(record)"
                           >
                            <a-icon type="plus" /> New Group
                        </div>

                    </div> -->
                    <a-select-option v-for="group in groups" :value="group.id" :key="group.id">{{group.name}}</a-select-option>
                </a-select>
                <span v-else>
                    {{groups.find(x => x.id === text).name}}
                </span>

            </template>

            <template slot="operation" slot-scope="text, record">
                <div class="editable-row-operations">
                    <span v-if="record.editable" style="display:flex;">
                        <a-button @click="save(record.id)" icon="check" type="primary" />
                        <a-popconfirm title="Sure to cancel?" @confirm="() => cancel(record.id)">
                            <a-button icon="close" class="ml-2"/>
                        </a-popconfirm>
                    </span>
                    <span v-else>
                        <a-button :disabled="editingKey !== ''" @click="() => edit(record.id)" type="secondary" icon="edit" size="small" />
                        <!-- <a :disabled="editingKey !== ''" @click="() => edit(record.id)">Edit</a> -->
                    </span>
                </div>
            </template>
        </a-table>

        <template v-if="origFeatures && origFeatures.length">
            <div class="w-full dF jE">
                <div class="dF aC">
                    <i @click="changeDisplay('card')" :style="viewType == 'card'? 'color:var(--orange)': 'color:#D3D0D6'"  style="cursor:pointer; font-size:25px" class="mr-3 fa fa-th" />
                    <i @click="changeDisplay('list')" :style="viewType == 'list'? 'color:var(--orange)': 'color:#D3D0D6'" style="cursor: pointer; font-size:25px" class="fa fa-th-list" />
                </div>
            </div>
			<div class="w-full dF jE mt-2">
				<a-select @change="selectedBulk" class="change-select-color" v-if="selectedFeatures.length > 1 && $p > 10" placeholder="Bulk Actions" style="width:200px;">
					<a-select-option value="delete">Delete Selected Features</a-select-option>
				</a-select>
			</div>
            <template v-if="viewType == 'card'">
				<div v-for="(group,groupI) in groupsWithFeatures" :key="group+groupI" :class="groupI == 0 ? 'mt-3' : 'mt-5'">
					<h5 class="mb-3">{{origGroups && origGroups[group] && origGroups[group].name}}</h5>
					<div class="grid-features">
						<template v-for="(feature,featureI) in origFeatures" >
							<FeaturesCard v-if="feature.group == group" :key="feature+featureI" :feature="feature" :selected="selectedFeatures" @selectFeature="selectFeature"/>
						</template>
					</div>
				</div>
            </template>
            <div v-else class="w-full mt-4">
                <a-table :rowKey="(e) => e.id" class="white-table" :columns="newColumns" :data-source="origFeatures" :pagination="false" :row-selection="{ selectedRowKeys: selectedFeatures, onChange: onSelectChange }">
                    <div slot="thumbnail" slot-scope="obj">
                        <img :src="obj.thumbnail" style="object-fit: contain; width: 150px; max-height: 100px;" />
                    </div>
                    <div slot="description" slot-scope="obj" style="color:#9EA0A5">
                        <div v-html="obj.description"></div>
                    </div>
                    <div slot="visibility" slot-scope="obj" style="font-weight: bold;">
                        {{obj.status}}
                    </div>
                    <div slot="action" slot-scope="obj" class="dF">
                        <a-popover overlayClassName="no-padding" trigger="focus">
                            <div slot="content">
								<div @click="previewFeature(obj)" style="cursor:pointer" class="option dF aC p-2">
									<div class="mr-3">
										<a-icon type="eye" />
									</div>
									<div>Preview</div>
								</div>
                                <div @click="editFeature(obj)" style="cursor:pointer" class="option dF aC p-2">
                                    <div class="mr-3">
                                        <a-icon type="edit" />
                                    </div>
                                    <div>Edit</div>
                                </div>
                                <div @click="duplicateFeature(obj)" style="cursor:pointer" class="option dF aC p-2">
                                    <div class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg></div>
                                    <div>Duplicate</div>
                                </div>
                                <div v-if="$p > 10" @click="deleteFeature(obj)" style="cursor:pointer" class="option dF aC p-2">
                                    <div class="mr-3" style="color:#FD647C"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></div>
                                    <div>Delete</div>
                                </div>
                            </div>
                            <div class="relative more-option dF aC" tabindex="0">
                                <div class="absolute circle" style="background-color:#fff; width:42px; height:42px; border-radius:50%; left:-15px"></div>
                                <a-icon class="more-icon" style="z-index:10" type="more" />
                            </div>
                        </a-popover>
                    </div>
                </a-table>
            </div>
        </template>
    </div>
</template>


<script>




import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import FeaturesCard from '@/components/features/FeaturesCard'
import Tiptap from '../TipTap.vue'

export default {
    components:{
        Tiptap,
        ImageBoxSelector,
        FeaturesCard,
    },
    data() {
        return {
            newColumns:[
                {
                    title:'Thumbnail',
                    key:'thumbnail',
                    slots:{title:'thumbnail'},
                    scopedSlots:{customRender:'thumbnail'}
                },
                {
                    dataIndex:"name",
                    key:"name",
                    title:'Title'
                },
                {
                    title:'Description',
                    key:'description',
                    slots:{title:'description'},
                    scopedSlots:{customRender:'description'}
                },
                {
                    title:'Visibility',
                    key:'visibility',
                    slots:{title:'visibility'},
                    scopedSlots:{customRender:'visibility'}
                },
                {
                    title:'',
                    key:'action',
                    slots:{title:'Actions'},
                    scopedSlots:{customRender:'action'}
                }
            ],
            viewType:'card',


            newGroupModalProcessing:false,
            manageGroupNew:false,
            newGroupName:'',
            newFeatureModal:{
                show:false,
                processing:false,
                showError:false,

            },
            newFeature:{
                processing:false,
                name:'',
                description:'',
                order:1,
                thumbnail:'',
                group:'',
                data:{
                    mediaType:'image',
                    media:''
                }
            },
            editGroup:{
                show:false,
                id:'',
                name:''
            },
            manageGroupModal:{
                show:false,
            },
            newGroupModal:{
                show:false,
                processing:false,
                id:'',
                name:'',
            },
            isLoading:false,
            columnsOriginal: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    width: '25%',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: 'Description',
                    dataIndex: 'description',
                    width: '40%',
                    scopedSlots: { customRender: 'description' },
                },
                {
                    title: 'Thumbnail',
                    dataIndex: 'thumbnail',
                    scopedSlots: { customRender: 'thumbnail' },
                },
                {
                    title: 'Media',
                    dataIndex: 'data',
                    scopedSlots: { customRender: 'data' },
                },
                {
                    title: 'Group',
                    dataIndex: 'group',
                    width:'15%',
                    scopedSlots: { customRender: 'group1' },
                    filters: [
                    { text: 'Group uno', value: 'group1' },
                    { text: 'Group Duox', value: 'group2' },
                    ],
                },
                {
                    title: 'Order',
                    dataIndex: 'order',
                    width: '15%',
                    scopedSlots: { customRender: 'order' },
                },
                {
                    title: '',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
            ],
            editingKey: '',
            features:[],
            cacheFeatures:[],
            filters:{},

			selectedFeatures:[]
        };
    },
    watch:{
        newFeatureModal:{
            handler(val, oldVal){
                if (val.show && val.show !== oldVal.show){
                    this.editingKey = ''
                    this.newFeature = {
                        name:'',
                        description:'',
                        order:1,
                        thumbnail:'',
                        group:this.groups.length?this.groups[0].id : '',
                        data:{
                            mediaType:'image',
                            media:''
                        }
                    }

                } else if (!val.show){
                    this.newFeature = {
                        name:'',
                        description:'',
                        thumbnail:'',
                        group:this.groups.length?this.groups[0].id : '',
                        data:{
                            mediaType:'image',
                            media:''
                        }
                    }
                }
            },
            deep:true
        },
        filters(val){
            this.editingKey = ''
            this.filterFeatures()
        },
        manageGroupNew(){
            this.newGroupName = ''
        },
    },
    methods: {
		selectedBulk(e){
			console.log('E', e);
			if (e == 'delete'){
				let self = this;
				this.$confirm({
					title: 'Bulk Delete',
					content: 'Are you sure you want to delete these Features?',
					okText: 'Yes',
					okType: 'danger',
					centered: true,
					cancelText: 'No',
					onOk() {
						self.deleteFeatures();
					},
					onCancel() {
						console.log('Cancel');
					},
				});
			}
		},
		deleteFeatures(){
			let sendObj = {ids:this.selectedFeatures}
			this.isLoading = true;
			this.$api.post(`/features/:instance/bulk-delete`, sendObj).then(({data}) =>{
				console.log('DATA COMING BACK', data);
				if (data.success){
					this.$store.commit('REMOVE_FEATURES', this.selectedFeatures);
				}
				this.isLoading = false;
				this.selectedFeatures = [];
			}).catch(err=>{
				console.log('err', err);
				this.isLoading = false;
			})
		},
		onSelectChange(selectedRowKeys) {
			console.log('selectedRowKeys changed: ', selectedRowKeys);
			this.selectedFeatures = selectedRowKeys;
		},
		selectFeature(id){
			if (this.selectedFeatures.includes(id)){
				this.selectedFeatures = this.selectedFeatures.filter(item => item !== id)
			} else {
				this.selectedFeatures.push(id)
			}
		},
		previewFeature(obj){
			console.log('PREVIEWING FEATURE...', obj)
			this.$store.commit('SHOW_PREVIEW', obj)
		},
        changeDisplay(type){
            console.log('CHANGING DISPLAY...')
            this.viewType = type
        },
        editFeature(obj){
            console.log('EDITING FEATURE...', obj)
            this.$router.push('/editfeatures/' + obj.id)
        },
        duplicateFeature(obj){
            console.log('DUPLICATING FEATURE...', obj)
            let newObj = JSON.parse(JSON.stringify(obj))
            newObj.name = obj.name + ' (copy)'
            delete newObj.id

            this.$store.commit('LOAD_PAGE',true)
            this.$api.post(`/features/${this.$store.state.instance.id}`, newObj).then( ( {data} ) => {

                data.group = typeof data.group === 'object'? data.group.id : data.group
                this.$store.commit('LOAD_PAGE',false)
                this.$store.commit('SET_PROP',{where:['features', data.id], what:data})

                console.log('ORIGINAL FEATURES', this.origFeatures)

                this.$message.success('Successfully duplicated Feature')

            }).catch(err => {

                this.$store.commit('LOAD_PAGE',false)
                console.error('ERRR', this.$err(err))
                this.$message.error(this.$err(err))

            })
        },
        deleteFeature(obj){
            console.log('DELETING FEATURE...', obj)
            if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')

            let self = this
            this.$confirm({
                title: 'Delete Feature',
                content: h => <div>Do you want to delete this feature?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered:true,
                onOk() {
                    console.log('CONFIRM DELETE', obj)
                    self.$api.delete(`/features/:instance/${obj.id}`).then( ({data}) => {
                        console.log('DATA FROM DELETE API ', data)
                        self.$store.commit('SET_PROP',{where:['features', data.id], what:data, del:true})
                    }).catch(err => {
                        console.error('ERRR', self.$err(err))
                        self.$message.error(self.$err(err))
                    })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
        addFeature(){
            if (!this.newFeature.name.trim() || !this.newFeature.thumbnail || !this.newFeature.data.media) return this.$message.error('Please review and try again.'),this.newFeatureModal.showError = true

            this.newFeatureModal.processing = true

            let {group} = this.newFeature
            let features = this.origFeatures.filter(x => x.group).filter(x => {
                let groupid = x.group.id ? x.group.id : x.group
                return groupid === group
            })
            let lastNum = 0
            features.forEach(f => {
                if (f.order > lastNum) lastNum = f.order
            })


            this.newFeature.order = lastNum + 1


            this.$api.post(`/features/${this.$store.state.instance.id}`, this.newFeature).then( ( {data} ) => {

                data.group = typeof data.group === 'object'? data.group.id : data.group


                this.newFeatureModal.processing = false
                this.$store.commit('SET_PROP',{where:['features', data.id], what:data})
                this.features = JSON.parse(JSON.stringify(this.origFeatures))

                this.cacheFeatures = JSON.parse(JSON.stringify(this.origFeatures))

                console.log('ORIGINAL FEATURES', this.origFeatures )

                this.$message.success('Successfully added Category')
                this.closeNewFeature()

            }).catch(err => {

                this.newFeatureModal.processing = false
                console.error('ERRR', this.$err(err))
                this.$message.error(this.$err(err))

            })


        },
        closeNewFeature(){
            this.newFeatureModal.show = false
        },
        closeNewGroup(){
            this.newGroupName = ''
            if (this.newGroupModalProcessing) return false
            this.manageGroupNew = false
        },
        makeNewGroup(){
            if (!this.newGroupName.trim()) return this.$message.error('Name cannot be blank.')
            this.newGroupModalProcessing = true
            console.log('NEW ', this.newGroupName)

            this.addGroup(this.newGroupName).then( () => {
                console.log('DONEEEE')
                this.newGroupModalProcessing = false
                this.manageGroupNew = false
            })


        },
        hideManageGroup(){

        },
        resetEditGroup(){
            this.editGroup = {
                show:false,
                name:'',id:''
            }
        },
        async doEditGroup(grp){
            if (!this.editGroup.name.trim()) return this.$message.error('Name cannot be blank')
            if (grp.name !== this.editGroup.name.trim()){
                let {data} = await this.$api.put(`/feature-groups/${this.$store.state.instance.id}/${grp.id}`, {name:this.editGroup.name.trim()})
                if (data.id) this.$message.success('Successfully Updated'), this.$store.commit('SET_PROP',{})
            }
            this.resetEditGroup()
        },
        showEditGroup({name,id}){
            this.editGroup = {
                show:true,
                name,id
            }
        },
        addGroup(name = ''){
            return new Promise( (resolve,reject) => {

                if (!name) return this.$message.error('Name cannot be blank.'),reject()

                this.newGroupModalProcessing = true

                this.$api.post(`/feature-groups/${this.$store.state.instance.id}`, {
                    name
                }).then( ({data}) => {
                    this.newGroupModalProcessing = false

                    this.$store.commit('SET_PROP',{where:['groups', data.id], what:data})
                    this.$message.success('Successfully added Category')
                    resolve()
                    console.log('DATA FROM API ', data)
                }).catch(err => {
                    this.newGroupModalProcessing = false
                    console.error('ERRR', this.$err(err))
                    this.$message.error(this.$err(err))
                    reject(this.$err(err))
                })
            })

        },
        closeModals(){

            this.manageGroupModal = {
                show:false
            }
        },
        showAddGroup(rec){
            this.newGroupModal.show = true
            this.newGroupModal.id = rec.id
        },
        filterFeatures(){
            let feats = this.filters
            let keys = Object.keys(feats)
            let features = JSON.parse(JSON.stringify(this.origFeatures))
            if (keys.length === 0) return this.features = features
            this.features = features.filter(feat => {
                let result = true
                keys.forEach(key => {
                    if (feats[key].length && !feats[key].find(v => feat[key] === v)) result = false
                })
                return result
            })
        },
        addNew(){
            console.log('ADDING NEW' )
            let id = '5c' + Date.now()
            this.features = [
                {
                    id,
                    name:'Name Goes Here',
                    group:'group1',
                    new:true,
                    description:'Description goes here.....',
                    order:1,
                    thumbnail:'',
                    editable:true,
                    data:{
                        mediaType:'image',
                        media:'',
                    }
                },
                ...this.features
            ]
            this.editingKey = id
        },
        handleTableChange(pagination, filters, sorter) {
            this.filters = filters
            console.log(pagination, filters, sorter);
            // const pager = { ...this.pagination };
            // pager.current = pagination.current;
            // this.pagination = pager;

            // this.fetch({
            //     results: pagination.pageSize,
            //     page: pagination.current,
            //     sortField: sorter.field,
            //     sortOrder: sorter.order,
            //     ...filters,
            // });
        },
        handleChange(value, key, column) {
            column = column.split('.')
            const newData = [...this.features];
            const target = newData.find(item => key === item.id);
            if (target) {
                if (column.length === 1) target[column[0]] = value;
                if (column.length === 2) target[column[0]][column[1]] = value;
                if (column.length === 3) target[column[0]][column[1]][column[2]] = value;
                this.features = newData;
            }

        },
        edit(key) {

            const newData = [...this.features];
            const target = newData.find(item => key === item.id)
            this.editingKey = key;
            if (target) {
                target.editable = true;
                this.features = newData;
            }

        },
        async save(key) {

            const newData = [...this.features];
            const newCacheData = [...this.cacheFeatures];
            const target = newData.find(item => key === item.id)
            const targetCache = newCacheData.find(item => key === item.id)


            if (target && targetCache ) {

                if (!target.name.trim() || !target.thumbnail.trim()) return this.$message.error('Please check your fields and try again.')

                this.isLoading = true

                // filterFeatures
                this.$api.put(`/features/${this.$store.state.instance.id}/${target.id}`, target).then( ( {data} ) => {
                    data.group = typeof data.group === 'object'? data.group.id : data.group

                    this.$store.commit('SET_PROP',{where:['features', data.id], what:data})
                    delete target.editable;
                    this.isLoading = false
                    this.features = newData;
                    Object.assign(targetCache, target);
                    this.cacheFeatures = newCacheData;
                    this.filterFeatures()

                }).catch(err => {

                    this.isLoading = false
                    console.error('ERRR', this.$err(err))
                    this.$message.error(this.$err(err))

                })




            }

            this.editingKey = '';

        },
        cancel(key) {
            const newData = [...this.features];
            const target = newData.find(item => key === item.id);
            this.editingKey = '';
            if (target) {
                Object.assign(target, this.cacheFeatures.find(item => key === item.id));
                delete target.editable;
                this.features = newData;
            }

        },
        deleteGroup(id){
            console.log('GROUP ID', id)
            this.$api.delete(`/feature-groups/:instance/${id}`).then( ({data}) => {
                console.log('DATA FROM API ', data)
            }).catch(err => {
                console.error('ERRR', this.$err(err))
                this.$message.error(this.$err(err))
            })
        }
    },
    computed:{
        groupsWithFeatures(){
            let list = []
            this.origFeatures.forEach(x => {
                if (x.group != '' && !list.includes(x.group)){
                    list.push(x.group)
                }
            })
            console.log('GROUPS WITH FEATURES', list)
            return list
        },
        columns(){
            let self = this
            return this.columnsOriginal.map(x =>{
                if (x.title !== 'Group') return x
                return {
                    ...x,
                    filters:self.groups.map(grp => ({
                        text:grp.name,
                        value:grp.id
                    }))
                }
            })
        },
        newTime(){
            return this.$store.state.features.newTime
        },
        origFeatures(){
            console.log('ORIGINAL FEATURES', Object.values(this.$store.state.features.features))
            return Object.values(this.$store.state.features.features)
        },
        groupsSelect(){
            return [...this.groups,{
                value:'add_new',

            }]
        },
        groups(){
            console.log('GROUPS', Object.values(this.$store.state.features.groups))
            return Object.values(this.$store.state.features.groups)
        },
		origGroups(){
			return this.$store.state.features.groups
		},
        bricks(){
            return Object.values(this.$store.state.features.bricks)
        },
    },
    created(){
        this.features = JSON.parse(JSON.stringify(this.origFeatures))
        this.features = this.features
        this.cacheFeatures = JSON.parse(JSON.stringify(this.origFeatures))
    },

};
</script>

<style scoped lang="scss">
    @media screen and (min-width: 1280px) {
        .grid-features{
            display:grid;
            grid-gap:25px;
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
    @media screen and (max-width: 1280px) {
        .grid-features{
            display:grid;
            grid-gap:25px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
    @media screen and (max-width: 768px) {
        .grid-features{
            display:grid;
            grid-gap:25px;
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
    .image-holder-box{
        width: 100%;
        max-width:100% !important;
    }
    .editable-row-operations a {
        margin-right: 8px;
    }
    .bh-file-selector img {
        max-width:100%;
    }
    .option:hover {
        background-color:#FAF9F9;
        cursor:pointer;
    }
    .more-option:hover .circle {
        opacity: 1;
        cursor:pointer;
    }
    .more-option:hover .more-icon {
        color:var(--orange);
        cursor:pointer;
    }
    .more-option:not(:hover) .circle {
        opacity: 0;
    }
</style>

<style lang="scss">
	.change-select-color .ant-select-selection__placeholder{
		color:#000 !important;
	}
    p {
        margin-bottom: 0;
    }
    .no-padding .ant-popover-inner-content{
        padding:0 !important;
        min-width:150px;
    }
    .preview-features-desc{
        font-size:10px;
        h3{
            font-size:12px !important;
        }
        > * {
            margin:0;
            & + *{
                margin-top:5px;
            }
        }
        max-height:40px;
        overflow: hidden;


    }

</style>
