<template>
  <a-modal
          :visible="previewModal.show"
          centered
          @ok="handleOk"
		  :width="'60%'"
		  :footer="null"
          @cancel="$store.commit('CLOSE_PREVIEW')"
        >
        <div class="mb-4" style="font-size: 2em;"><b>{{previewModal.data.name}}</b></div>
		<div class="w-full" style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); gap: 2em;">
			<template v-if="previewModal.data && previewModal.data.hasOwnProperty('image')">
				<img :src="previewModal.data.image" class="w-full" style="object-fit: contain;" />
			</template>
			<template v-else-if="previewModal.data && previewModal.data.data">
				<img :src="previewModal.data.data.media" class="w-full" style="object-fit: contain;" />
			</template>
			<div v-html="previewModal.data.description">
			</div>
		</div>
      </a-modal>
</template>

<script>
import {validateEmail,formatDate} from 'bh-mod'
export default {
    props:[''],
    data(){
        return {
            
        }
    },
    watch:{
        
    },
    computed:{
		previewModal(){
			return this.$store.state.features.previewModal
		},
        sender(){
            return this.senders.length && this.senders[0]
        },
        instance(){
            return this.$store.state.instance
        }
    },
    methods:{
        handleOk(){
            if (this.testMode) return this.sendTest() 
            this.sendBroadcast()
        },
        sendTest(){
            let url = `/services/email/send`
            console.log('SENDIN TEST')

            if (!this.testEmails.length) return this.$message.error('Please add atleast one email')

            let html = this.packageEmail()
            
            this.$api.post(url,{
                to:this.testEmails.join(','),
                subject:this.broadcast.subject,
                from:'promotion@test.bildhive.net',
                html
            })
        },
        packageEmail(){

            let fontGen = (font) => {
                return `<link data-font href="https://fonts.googleapis.com/css?family=${font.name.split(' ').join('+')}:${font.weights.join(',')}" rel="stylesheet" type="text/css">`
            }
            let fontGenStyle = (font) => {
                return `@import url(https://fonts.googleapis.com/css?family=${font.name.split(' ').join('+')}:${font.weights.join(',')});`
            }


            if (!this.dialog.data.html) return this.$message.error('No HTML found')
            if (!this.broadcast.subject.trim()) return this.$message.error('Please fill out Subject')

            let emailHTML = this.dialog.data.html 
            let html = document.createElement('html')
            html.innerHTML = emailHTML

            let parentNode = html.querySelector('body')
            let refChild = html.querySelector('body').children[0]
            let previewDOM = document.createElement('div')
            
            previewDOM.innerHTML = `<div style="display:none;font-size:1px;color:#ffffff;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden;"> 
                                        ${this.broadcast.previewText}
                                    </div>`

            parentNode.insertBefore(previewDOM, refChild)
            html = html.innerHTML
            html = `<!DOCTYPE html>↵<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">` + html + `</html>`
            html = html.replace(/\n/ig, '');
            html = html.replace('↵','')
            html = html.replace('https://mjml.io','')
            html = html.replace('[[SUBJECT]]',this.broadcast.subject)
            let fields = [
                {search:/%%COMMUNITYNAME%/gi, replace:this.instance.name},
                {search:/%%CONTACT_INFO%/gi, replace:`${this.instance.address}, ${this.instance.city} ${this.instance.region}`},
                {search:/%%name/gi, replace:'%recipient_name'},
                {search:/%%fname%/gi, replace:'%recipient.firstName%'},
                {search:/%%lname%/gi, replace:'%recipient.lastName%'},
                {search:/%%email%/gi, replace:'%recipient_email%'},
                {search:/%%5/gi, replace:'%recipient.5'},
            ]

            let fonts = this.dialog.data.options && this.dialog.data.options.fonts? this.dialog.data.options.fonts : false 
//          
//           <link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css">
//           <style type="text/css">
//                 @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
//           </style>
//     <!--<![endif]-->
            if (fonts){
                let fontHTML = fonts.map(x => fontGen(x)).join(' ')
                fontHTML = fontHTML  + `<style data-font>${fonts.map(x => fontGenStyle(x)).join(' ')}</style>`
                html = html.replace('<style', `<!--[if mso]>
                                    <xml>
                                    <o:OfficeDocumentSettings>
                                    <o:AllowPNG/>
                                    <o:PixelsPerInch>96</o:PixelsPerInch>
                                    </o:OfficeDocumentSettings>
                                    </xml>
                                    <![endif]-->
                                <!--[if lte mso 11]>
                                    <style type="text/css">
                                    .outlook-group-fix { width:100% !important; }
                                    </style>
                                    <![endif]--><!--[if !mso]><!-->${fontHTML}<!--<![endif]--> <style`)
            }
            
            fields.forEach( ({search,replace}) => {
                html = html.replace(search,replace)
            })
            
            return html
        },
        async sendBroadcast(){
            if (!!this.broadcast.name.trim()) this.broadcast.name = `${this.dialog.data.name}`
            if (!this.broadcast.allContacts && !this.broadcast.includeTags.value.length) return this.$message.error('Choose at least one tags to include')
            
            if (!this.sender.active) return this.$message.error('Sorry, the sender has not been verified yet. Please do so to start sending emails.')

            let prodHtml = this.packageEmail()
            this.$store.commit('LOAD_PAGE')

            let {data:emailSaved} = await this.$api.put(`/emails/${this.instance.id}/${this.dialog.data.id}`, {prodHtml} )

            if (emailSaved.id){
                console.log('SAVEDDDDDDDDDDDDD')
            } else {
                return this.$message.error('Something went wrong!')
            }
            let {includeTags,excludeTags,...sendOBJ} = this.broadcast

            if (!sendOBJ.allContacts){
                sendOBJ[`tagI_${includeTags.type}`] = includeTags.value
                sendOBJ[`tagE_any`] = excludeTags.value
            }

            let {data} = await this.$api.post(`/broadcasts/${this.instance.id}`,sendOBJ)
            this.$store.commit('LOAD_PAGE', false)
            this.$store.commit('CLOSE_DRAWERS')

        },
        handleClose(removedEmail) {
            const testEmails = this.testEmails.filter(email => email !== removedEmail);
            console.log(testEmails);
            this.testEmails = testEmails;
        },
        showInput() {
            this.inputVisible = true;
            this.$nextTick(function() {
                this.$refs.input.focus();
            });
        },
        handleInputChange(e) {
            this.inputValue = e.target.value;
        },
        handleInputConfirm(hideAfter = true) {
            
            this.newEmailError = false
   
            const inputValue = this.inputValue.toLowerCase().trim() 

            if (!inputValue) return this.inputVisible = false 

            if (!validateEmail(inputValue)) return this.newEmailError = true

            let testEmails = this.testEmails
            if (inputValue && testEmails.indexOf(inputValue) === -1) {
                testEmails = [...testEmails, inputValue];
            }
            
            let inputVisible = hideAfter

            Object.assign(this, {
                testEmails,
                inputVisible,
                inputValue: '',
                newEmailError: false,
            });
        },
    },
   
}
</script>

<style lang="scss">
    .ant-form-horizontal.form-horizontal{
        label{
        }
        .ant-form-item-control-wrapper{
            flex:1;
        }
        .ant-form-item{
            display:flex;
        }
        .ant-form-item-label{
            min-width:100px;
        }
  }
  input.has-error{
      border-color:var(--danger) !important;
  }
</style>