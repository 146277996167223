<template>
    <a-card :title="null" class="cursor-pointer purple-hover relative features-card" hoverable :bodyStyle="{padding:0}">
        <a-checkbox :checked="selected.includes(feature.id)" @change="selectFeature" class="absolute" style="right: 15px; top: 10px; z-index: 1;" />
		<div>
            <div class="px-3 pt-3" @click="editFeature()">
                <div class="image-holder" :style="{backgroundImage:`url(${feature.thumbnail})`}" />
            </div>
            <div class="dF aE p-3" style="text-align: left;">
                <div class="f1" @click="editFeature()">
                    <div style="font-size: 20px;">{{feature.name}}</div>
                    <div style="color: #9EA0A5; font-size: 16px;">{{feature.status ? feature.status.charAt(0).toUpperCase() + feature.status.slice(1) : 'Draft'}}</div>
                </div>
                <div>
                    <a-popover overlayClassName="no-padding" trigger="focus">
                        <div slot="content">
							<div @click="previewFeature()" style="cursor:pointer" class="option dF aC p-2">
                                <div class="mr-3">
                                    <a-icon type="eye" />
                                </div>
                                <div>Preview</div>
                            </div>
                            <div @click="editFeature()" style="cursor:pointer" class="option dF aC p-2">
                                <div class="mr-3">
                                    <a-icon type="edit" />
                                </div>
                                <div>Edit</div>
                            </div>
                            <div @click="duplicateFeature()" style="cursor:pointer" class="option dF aC p-2">
                                <div class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg></div>
                                <div>Duplicate</div>
                            </div>
                            <div v-if="$p > 10" @click="deleteFeature()" style="cursor:pointer" class="option dF aC p-2">
                                <div class="mr-3" style="color:#FD647C"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></div>
                                <div>Delete</div>
                            </div>
                        </div>
                        <div class="relative more-option dF aC" tabindex="0">
                            <div class="absolute circle" style="background-color:#fff; width:42px; height:42px; border-radius:50%; left:-15px"></div>
                            <a-icon class="more-icon" style="z-index:10" type="more" />
                        </div>
                    </a-popover>
                </div>
            </div>
        </div>
    </a-card>
</template>

<script>
export default {
    props:['feature', 'selected'],
    computed:{

    },
    methods:{
		selectFeature(){
			this.$emit('selectFeature', this.feature.id)
		},
		previewFeature(){
			console.log('PREVIEWING FEATURE...', this.feature)
			this.$store.commit('SHOW_PREVIEW', this.feature)
		},
        editFeature(){
            console.log('EDITING FEATURE...', this.feature)
            this.$router.push('/editfeatures/' + this.feature.id)
        },
        duplicateFeature(){
            console.log('DUPLICATING FEATURE...', this.feature)
            let newObj = JSON.parse(JSON.stringify(this.feature))
            newObj.name = this.feature.name + ' (copy)'
            delete newObj.id

            this.$store.commit('LOAD_PAGE',true)
            this.$api.post(`/features/${this.$store.state.instance.id}`, newObj).then( ( {data} ) => {

                data.group = typeof data.group === 'object'? data.group.id : data.group
                this.$store.commit('LOAD_PAGE',false)
                this.$store.commit('SET_PROP',{where:['features', data.id], what:data})

                console.log('ORIGINAL FEATURES', this.origFeatures)

                this.$message.success('Successfully duplicated Feature')

            }).catch(err => {

                this.$store.commit('LOAD_PAGE',false)
                console.error('ERRR', this.$err(err))
                this.$message.error(this.$err(err))

            })
        },
        deleteFeature(){
            console.log('DELETING FEATURE...', this.feature)
            if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')

            let self = this
            this.$confirm({
                title: 'Delete Feature',
                content: h => <div>Do you want to delete this feature?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered:true,
                onOk() {
                    console.log('CONFIRM DELETE', self.feature)
                    self.$api.delete(`/features/:instance/${self.feature.id}`).then( ({data}) => {
                        console.log('DATA FROM DELETE API ', data)
                        self.$store.commit('SET_PROP',{where:['features', data.id], what:data, del:true})
                    }).catch(err => {
                        console.error('ERRR', self.$err(err))
                        self.$message.error(self.$err(err))
                    })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    }
}
</script>

<style lang="scss">
    .no-padding .ant-popover-inner-content{
        padding:0 !important;
        min-width:150px;
    }
    .features-card{
        .image-holder{
            height:0;
            width:100%;
            padding-bottom:50%;
            position:relative;
            background-size:cover;
            background-repeat:no-repeat;
            background-position: center;
            background-color:var(--off-white-dark);
        }
    }
</style>

<style scoped>
    .option:hover {
        background-color:#FAF9F9;
        cursor:pointer;
    }
    .more-option:hover .circle {
        opacity: 1;
        cursor:pointer;
    }
    .more-option:hover .more-icon {
        color:var(--orange);
        cursor:pointer;
    }
    .more-option:not(:hover) .circle {
        opacity: 0;
    }
</style>
